import axios from "@/plugins/axios";
import {
  parse_type_material_to_create,
  parse_type_material_to_update,
} from "@/services/references/typeMaterial/parsers";

/**
 * Получение списка TypeMaterial
 * @return {Promise<TypeMaterialObject[]>}
 */
export const API_type_material_list = () => {
  return axios("/typeMaterial?perPage=5000", {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Получение TypeMaterial по ID
 * @param  {number} typeMaterialId
 * @return {Promise<TypeMaterialObject>}
 */
export const API_type_material_view = (typeMaterialId) => {
  return axios(`/typeMaterial/${typeMaterialId}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Получение TypeMaterial по ID
 * @param  {number} typeMaterialId
 * @param {Object} value
 * @return {Promise<TypeMaterialObject>}
 */
export const API_type_material_update = (typeMaterialId, value) => {
  return axios(`/typeMaterial/${typeMaterialId}`, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
    },
    data: parse_type_material_to_update(value),
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Создание TypeMaterial
 * @param {Object} value
 * @return {Promise<TypeMaterialObject>}
 */
export const API_type_material_create = (value) => {
  return axios(`/typeMaterial/`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: parse_type_material_to_create(value),
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Удаление TypeMaterial по ID
 * @param  {number} typeMaterialId
 * @return {Promise<boolean>}
 */
export const API_type_material_delete = (typeMaterialId) => {
  return axios(`/typeMaterial/${typeMaterialId}`, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
    },
  })
    .then(() => {
      return true;
    })
    .catch((error) => {
      throw error;
    });
};

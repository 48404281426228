<template>
  <div>
    <TableSlot
      :columns="tableColumns.workEquipmentsItems"
      :data="value"
      actions
    >
      <template v-slot:default="{ row }">
        <td>
          {{ row.WorkIdentifiedObject.IdentifiedObject.name }}
        </td>
        <td>
          {{ row.equipCode }}
        </td>
        <td>
          <EditableInput
            v-model="row.enproDuration.value"
            type="number"
            :editable="editable"
          />
        </td>
      </template>
      <template v-slot:actions="{ row, index }">
        <UIButton
          left-icon="bi-trash"
          float
          @click="addOrRemove(row, index)"
          :disabled="!editable"
        />
      </template>
    </TableSlot>
    <div class="d-flex justify-content-center py-2">
      <Modal
        title="Машины"
        button-text="Добавить"
        button-left-icon="bi-plus"
        :button-disabled="!editable"
      >
        <template v-slot:default>
          <TableSlot
            :columns="tableColumns.workEquipments"
            :data="workEquipmentsList"
            @rowClick="addOrRemove"
          >
          </TableSlot>
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import TableSlot from "@/components/TableSlot";
import EditableInput from "@/components/UI/EditableInput";
import Modal from "@/components/UI/Modal";
import UIButton from "@/components/UI/Button";
import { API_work_equipment_list } from "@/services/references/workEquipment";
import modelGenerator from "@/plugins/modelGenerator";
import { updateWorkTaskWorkEquipmentItems } from "@/services/work/schemas";

export default {
  name: "workEquipmentItems",
  components: { TableSlot, EditableInput, Modal, UIButton },
  props: {
    value: Array,
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableColumns: {
        workEquipmentsItems: [
          {
            name: "Наименование",
            path: "WorkIdentifiedObject.IdentifiedObject.name",
          },
          {
            name: "Код машины",
            path: "equipCode",
          },
          {
            name: "Объем",
            path: "enproDuration.value",
          },
        ],
        workEquipments: [
          {
            name: "Наименование",
            path: "WorkIdentifiedObject.IdentifiedObject.name",
          },
          {
            name: "Код",
            path: "code",
          },
          {
            name: "Тариф",
            path: "rate.value",
          },
          {
            name: "Статус",
            path: "status.value",
          },
        ],
      },
      workEquipmentsList: [],
    };
  },
  created() {
    API_work_equipment_list().then((res) => {
      this.workEquipmentsList = res;
    });
  },
  methods: {
    addOrRemove(item, index = -1) {
      if (index > -1) {
        this.value.splice(index, 1);
      } else {
        this.value.push(
          modelGenerator(updateWorkTaskWorkEquipmentItems, {
            equipCode: item.WorkIdentifiedObject.IdentifiedObject.enproCode,
            costType: null,
            WorkIdentifiedObject: {
              IdentifiedObject: {
                name: item.WorkIdentifiedObject.IdentifiedObject.name,
              },
            },
            cost: {
              value: null,
            },
            enproDuration: {
              value: 1,
            },
            rate: {
              value: item.rate.value,
            },
            status: {
              id: 25,
            },
            WorkAsset: null,
            WorkCostDetail: null,
          })
        );
      }
    },
  },
};
</script>

<style scoped></style>

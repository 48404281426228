<template>
  <div>
    <TableSlot :columns="tableColumns.materialItems" :data="value" actions>
      <template v-slot:default="{ row }">
        <td>
          {{ lodash.get(row, "IdentifiedObject.name") }}
        </td>
        <td>
          <EditableInput
            v-model="row.quantity.value"
            type="number"
            :editable="editable"
          />
        </td>
        <td>
          {{
            lodash.get(
              lodash.find(materialList, {
                id: row.type_material_id,
              }),
              "quantity",
              "загрузка.."
            )
          }}
        </td>
      </template>
      <template v-slot:actions="{ row, index }">
        <UIButton
          left-icon="bi-trash"
          float
          @click="addOrRemove(row, index)"
          :disabled="!editable"
        />
      </template>
    </TableSlot>
    <div class="d-flex justify-content-center py-2">
      <Modal
        title="Материалы"
        button-text="Добавить"
        button-left-icon="bi-plus"
        :button-disabled="!editable"
      >
        <template v-slot:default>
          <TableSlot
            :columns="tableColumns.materials"
            :filters="filters"
            :data="
              materialList.filter(
                (item) =>
                  !value
                    .map((e) => e.type_material_id)
                    .some((i) => i === item.id)
              )
            "
            @rowClick="addOrRemove"
          >
          </TableSlot>
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import EditableInput from "@/components/UI/EditableInput";
import TableSlot from "@/components/TableSlot";
import UIButton from "@/components/UI/Button";
import Modal from "@/components/UI/Modal";
import { API_type_material_list } from "@/services/references/typeMaterial";
import modelGenerator from "@/plugins/modelGenerator";
import { updateWorkTaskMaterialItems } from "@/services/work/schemas";

export default {
  name: "materialItems",
  components: { EditableInput, TableSlot, UIButton, Modal },
  props: {
    value: Array,
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableColumns: {
        materialItems: [
          {
            name: "Наименование",
            path: "IdentifiedObject.name",
          },
          {
            name: "Количество",
            path: "quantity",
          },
          {
            name: "ЕИ",
            path: "quantity",
          },
        ],
        materials: [
          {
            name: "Код",
            path: "WorkDocument.Document.IdentifiedObject.enproCode",
          },
          {
            name: "Наименование",
            path: "WorkDocument.Document.IdentifiedObject.name",
          },
          {
            name: "Тип затрат",
            path: "costType",
          },
          {
            name: "ЕИ",
            path: "quantity",
          },
          {
            name: "Цена единицы",
            path: "estUnitCost.value",
          },
          {
            name: "Статус",
            path: "WorkDocument.Document.status.value",
          },
        ],
      },
      filters: {
        "WorkDocument.Document.IdentifiedObject.enproCode": "",
        "WorkDocument.Document.IdentifiedObject.name": "",
      },
      materialList: [],
    };
  },
  mounted() {
    API_type_material_list().then((res) => {
      this.materialList = res;
    });
  },
  methods: {
    addOrRemove(item, index = -1) {
      if (index > -1) {
        this.value.splice(index, 1);
      } else {
        this.value.push(
          modelGenerator(updateWorkTaskMaterialItems, {
            type_material_id: item.id,
            quantity: { value: 1 },
            IdentifiedObject: {
              name: item.WorkDocument.Document.IdentifiedObject.name,
            },
          })
        );
      }
    },
  },
};
</script>

<style scoped></style>

export let createTypeMaterialSchema = {
  WorkDocument: {
    Document: {
      IdentifiedObject: {
        name: "", //Название
        description: "",
        enproCode: "",
        names: [],
      },
      type: "", //Тип документа
      status_id: 0,
    },
  },
  costType: "", //Тип затрат
  estUnitCost: {
    value: 0, //Стоимость
  },
  quantity: "", //Единица измерения типа материала
  stockItem: true, //Складская позиция
  unit_id: 0, //id Единицы измерения типа материала (UnitSymbol)
  multiplier_id: 0, //id мультипликатора Единицы измерения типа материала (UnitMultiplier)
};

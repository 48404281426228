import axios from "@/plugins/axios";

export const API_crew_list = () => {
  return axios(`/crew?perPage=5000`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      return response.data.data.map((item) => ({
        id: item.id,
        value: item.IdentifiedObject.name,
      }));
    })
    .catch((error) => {
      throw error;
    });
};

export const API_crewMembers_list = () => {
  return axios(`/crewMember?perPage=5000`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const API_crew_view = (crewId) => {
  return axios(`/crew/${crewId}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

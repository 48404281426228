export const getPersonName = (person) => {
  if (person) {
    if (person.lastName || person.firstName || person.mName) {
      let _s = [];
      if (person.lastName) _s.push(person.lastName);
      if (person.firstName) _s.push(person.firstName);
      if (person.mName) _s.push(person.mName);
      return _s.join(" ");
    }
    return `Id пользователя: ${person.id}`;
  }
  return " - ";
};

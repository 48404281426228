<template>
  <div>
    <TableSlot :columns="tableColumns.laborItems" :data="value" actions>
      <template v-slot:default="{ row }">
        <td>{{ row.WorkIdentifiedObject.IdentifiedObject.name }}</td>
        <td>
          <EditableInput
            v-model="row.laborDuration.value"
            type="number"
            :editable="editable"
          />
        </td>
        <td>
          <p v-for="(person, idx) in getCrew(row.ErpPersons)" :key="idx">
            {{ person }}
          </p>
          <!--          {{ row.ErpPersons }}-->
        </td>
      </template>
      <template v-slot:actions="{ row, index }">
        <div class="d-inline-flex gap-2">
          <Modal
            title="Состав"
            button-text="Изменить состав"
            :button-disabled="!editable"
          >
            <template v-slot:default>
              <div>
                <dt class="col">Подразделение:</dt>
                <dl class="col">
                  <EditableSelect
                    v-model="crew.crewId"
                    :options="crew.smallAsset"
                    :editable="editable"
                  />
                </dl>
              </div>
              <div>
                <div
                  class="form-check"
                  v-for="item in crew.crewMembers"
                  :key="item.id"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :checked="
                      row.ErpPersons.find(
                        (i) =>
                          i.id ===
                          item.OperationPersonRole.PersonRole.Person.OldPerson
                            .id
                      )
                    "
                    @input="addOrRemoveCrewMember(row, item)"
                    value=""
                    :id="'crewMember' + item.id"
                  />
                  <label class="form-check-label" :for="'crewMember' + item.id">
                    {{
                      getCrewName(
                        lodash.get(
                          item,
                          "OperationPersonRole.PersonRole.Person"
                        )
                      )
                    }}
                    ({{
                      lodash.get(
                        item,
                        "OperationPersonRole.PersonRole.IdentifiedObject.name"
                      )
                    }})
                  </label>
                </div>
              </div>
            </template>
          </Modal>
          <UIButton
            left-icon="bi-trash"
            float
            @click="addOrRemove(row, index)"
            :disabled="!editable"
          />
        </div>
      </template>
    </TableSlot>
    <div class="d-flex justify-content-center py-2">
      <Modal
        title="Трудозатраты"
        button-text="Добавить"
        button-left-icon="bi-plus"
        :button-disabled="!editable"
      >
        <template v-slot:default>
          <TableSlot
            :columns="tableColumns.labor"
            :data="laborCodeList"
            @rowClick="addOrRemove"
          >
          </TableSlot>
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import TableSlot from "@/components/TableSlot";
import EditableSelect from "@/components/UI/EditableSelect";
import EditableInput from "@/components/UI/EditableInput";
import Modal from "@/components/UI/Modal";
import UIButton from "@/components/UI/Button";
import {
  API_crew_list,
  API_crew_view,
  API_crewMembers_list,
} from "@/services/references/crew";
import modelGenerator from "@/plugins/modelGenerator";
import { updateWorkTaskLaborItem } from "@/services/work/schemas";
import { API_labor_list } from "@/services/references/labor";

export default {
  name: "laborItems",
  components: { TableSlot, EditableSelect, EditableInput, Modal, UIButton },
  props: {
    value: Array,
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      crew: {
        crewId: null,
        smallAsset: [],
        crewMembers: [],
        allCrewMembers: [],
      },
      laborCodeList: [],
      tableColumns: {
        laborItems: [
          {
            name: "Единица труда",
            path: "WorkIdentifiedObject.IdentifiedObject.name",
          },
          {
            name: "Длительность",
            path: "laborDuration.value",
          },
          {
            name: "Состав",
            path: "ErpPersons",
          },
        ],
        labor: [
          {
            name: "Наименование",
            path: "WorkIdentifiedObject.IdentifiedObject.name",
          },
          {
            name: "Код",
            path: "code",
          },
          {
            name: "Тариф",
            path: "enproRate.value",
          },
          {
            name: "Статус",
            path: "status.value",
          },
        ],
      },
    };
  },
  mounted() {
    API_crew_list().then((res) => (this.crew.smallAsset = res));
    API_labor_list().then((res) => {
      this.laborCodeList = res;
    });
    API_crewMembers_list().then((res) => {
      this.crew.allCrewMembers = res;
    });
  },
  watch: {
    "crew.crewId": function () {
      API_crew_view(this.crew.crewId).then(
        (res) =>
          (this.crew.crewMembers = res.CrewMembers.filter(
            (i) => i.OperationPersonRole.PersonRole.Person
          ))
      );
    },
  },
  methods: {
    getCrewName(person) {
      if (person) {
        if (person.lastName || person.firstName || person.mName) {
          let _s = [];
          if (person.lastName) _s.push(person.lastName);
          if (person.firstName) _s.push(person.firstName);
          if (person.mName) _s.push(person.mName);
          return _s.join(" ");
        }
        return `Id пользователя: ${person.id}`;
      }
      return "---";
    },
    getCrew(CrewList) {
      return Array.from(CrewList, (i) => {
        const _d = this.lodash.find(this.crew.allCrewMembers, {
          OperationPersonRole: {
            PersonRole: {
              Person: {
                OldPerson: {
                  id: i.id,
                },
              },
            },
          },
        });
        if (_d) {
          const name = this.getCrewName(
            this.lodash.get(_d, "OperationPersonRole.PersonRole.Person")
          );
          const job = this.lodash.get(
            _d,
            "OperationPersonRole.PersonRole.IdentifiedObject.name",
            "---"
          );
          return `${name} (${job})`;
        }
        return "---";
      });
    },
    addOrRemoveCrewMember(row, item) {
      if (
        row.ErpPersons.find(
          (i) =>
            i.id === item.OperationPersonRole.PersonRole.Person.OldPerson.id
        )
      ) {
        row.ErpPersons.splice(
          row.ErpPersons.findIndex(
            (i) =>
              i.id === item.OperationPersonRole.PersonRole.Person.OldPerson.id
          ),
          1
        );
      } else {
        row.ErpPersons.push({
          id: item.OperationPersonRole.PersonRole.Person.OldPerson.id,
        });
      }
    },
    addOrRemove(item, index = -1) {
      if (index > -1) {
        this.value.splice(index, 1);
      } else {
        this.value.push(
          modelGenerator(updateWorkTaskLaborItem, {
            activityCode: item.code,
            WorkIdentifiedObject: {
              IdentifiedObject: {
                name: item.WorkIdentifiedObject.IdentifiedObject.name,
              },
            },
            WorkCostDetail: null,
            cost: {
              value: null,
            },
            laborDuration: {
              value: 1,
            },
            laborRate: {
              value: item.enproRate.value,
            },
            status: {
              id: 25,
            },
            ErpPersons: [],
          })
        );
      }
    },
  },
};
</script>

<style scoped></style>

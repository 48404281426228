<template>
  <div>
    <TableSlot :columns="tableColumns" :data="value" actions>
      <template v-slot:default="{ row }">
        <td>
          <EditableInput
            v-model="row.WorkIdentifiedObject.IdentifiedObject.name"
            :editable="editable"
          />
        </td>
        <td>
          <EditableInput
            v-model="row.costPerUnit.value"
            type="number"
            :editable="editable"
          />
        </td>
        <td>
          <EditableInput
            v-model="row.quantity.value"
            type="number"
            :editable="editable"
          />
        </td>
        <td>
          {{
            row.enproCost.value
              ? row.enproCost.value
              : row.quantity.value * row.costPerUnit.value
          }}
        </td>
      </template>
      <template v-slot:actions="{ row, index }">
        <UIButton
          left-icon="bi-trash"
          float
          @click="addOrRemove(index)"
          :disabled="!editable"
        />
      </template>
    </TableSlot>
    <div class="d-flex justify-content-center py-2">
      <UIButton
        left-icon="bi-plus"
        text="Добавить"
        @click="addOrRemove()"
        :disabled="!editable"
      />
    </div>
  </div>
</template>

<script>
import EditableInput from "@/components/UI/EditableInput";
import TableSlot from "@/components/TableSlot";
import UIButton from "@/components/UI/Button";
import modelGenerator from "@/plugins/modelGenerator";
import { updateWorkTaskMiscCostItems } from "@/services/work/schemas";

export default {
  name: "miscCostItems",
  components: { TableSlot, EditableInput, UIButton },
  props: {
    value: Array,
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableColumns: [
        {
          name: "Наименование",
          path: "WorkIdentifiedObject.IdentifiedObject.name",
        },
        {
          name: "Цена за ед.",
          path: "costPerUnit.value",
        },
        {
          name: "Объем",
          path: "quantity.value",
        },
        {
          name: "Итого",
          path: "enproCost.value",
        },
      ],
    };
  },
  methods: {
    addOrRemove(index = -1) {
      if (index > -1) {
        this.value.splice(index, 1);
      } else {
        this.value.push(
          modelGenerator(updateWorkTaskMiscCostItems, {
            WorkIdentifiedObject: {
              IdentifiedObject: {
                name: "",
              },
            },
            costPerUnit: {
              value: 0,
            },
            enproCost: {
              value: 0,
            },
            quantity: {
              value: 1,
            },
          })
        );
      }
    },
  },
};
</script>

<style scoped></style>

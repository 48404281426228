<template>
  <div>
    <div class="d-flex justify-content-between">
      <h2 v-text="titlePage"></h2>
      <div>
        <div class="d-inline-flex gap-3">
          <UIButton
            type="button"
            v-show="!editable"
            text="Редактировать"
            leftIcon="bi-pencil"
            :disabled="!workTask"
            @click="goEdit"
          />
          <UIButton
            type="button"
            v-show="editable"
            text="Сохранить"
            leftIcon="bi-save"
            :disabled="!workTask"
            @click="update"
            ref="submit"
          />
          <UIButton
            type="submit"
            v-show="editable && $attrs.mode !== 'create'"
            text="Отмена"
            leftIcon="bi-save"
            :disabled="!workTask"
            @click="goToView($route.params.order, $route.params.workTask)"
          />
          <Modal
            v-show="editable && $attrs.mode !== 'create'"
            title="Удаление работы"
            button-text="Удалить"
            button-left-icon="bi-trash"
            :button-disabled="!workTask"
            size="sm"
            @submit="remove"
          >
            <template v-slot:default>
              <p>Вы уверены?</p>
            </template>
            <template v-slot:actions>
              <UIButton type="submit" text="Удалить" />
            </template>
          </Modal>
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-center align-items-center"
      style="height: 400px"
      v-if="!workTask"
    >
      <i class="spinner-border" role="status"></i>
    </div>
    <div v-if="workTask">
      <div class="row gap-2">
        <dl class="col">
          <div class="row gap-2">
            <div>
              <dt class="col">Наименование:</dt>
              <dl class="col">
                <EditableInput
                  v-model="workTask.BaseWork.Document.IdentifiedObject.name"
                  :editable="editable"
                />
              </dl>
            </div>

            <div>
              <dt class="col">Дата начала:</dt>
              <dl class="col">
                <EditableInput
                  :value="
                    new Date(
                      lodash.get(
                        workTask,
                        'BaseWork.TimeSchedules[0].TimeSchedule.scheduleInterval.start'
                      )
                    )
                      .toISOString()
                      .slice(0, 10)
                  "
                  @input="
                    (e) => {
                      lodash.set(
                        workTask,
                        'BaseWork.TimeSchedules[0].TimeSchedule.scheduleInterval.start',
                        new Date(e).toISOString()
                      );
                    }
                  "
                  type="date"
                  :editable="editable"
                />
              </dl>
            </div>

            <div>
              <dt class="col">Дата окончания:</dt>
              <dl class="col">
                <EditableInput
                  :value="
                    new Date(
                      lodash.get(
                        workTask,
                        'BaseWork.TimeSchedules[0].TimeSchedule.scheduleInterval.end'
                      )
                    )
                      .toISOString()
                      .slice(0, 10)
                  "
                  @input="
                    (e) => {
                      lodash.set(
                        workTask,
                        'BaseWork.TimeSchedules[0].TimeSchedule.scheduleInterval.end',
                        new Date(e).toISOString()
                      );
                    }
                  "
                  type="date"
                  :editable="editable"
                />
              </dl>
            </div>

            <div>
              <dt class="col">Тип графика:</dt>
              <dl class="col">
                <EditableSelect
                  :value="
                    lodash.get(workTask, 'BaseWork.TimeSchedules[0].kind_id')
                  "
                  @input="
                    (e) => {
                      lodash.set(
                        workTask,
                        'BaseWork.TimeSchedules[0].kind_id',
                        e
                      );
                    }
                  "
                  :options="workTimeScheduleKind"
                  :editable="editable"
                />
              </dl>
            </div>

            <div>
              <dt class="col">Место проведение работ:</dt>
              <dl class="col">
                <EditableSelectWithSearch
                  v-model="workTask.BaseWork.WorkLocation.location_id"
                  :options="smallAssetLocations"
                  :editable="editable"
                />
              </dl>
            </div>
          </div>
        </dl>
        <dl class="col">
          <div class="row gap-2">
            <div>
              <dt class="col">Ответственный:</dt>
              <dl class="col">
                <PersonCombobox
                  :value="
                    lodash.get(
                      workTask,
                      'BaseWork.Document.Issuer.DocumentPersonRole.PersonRole.person_id',
                      null
                    )
                  "
                  @input="
                    (e) =>
                      lodash.set(
                        workTask,
                        'BaseWork.Document.Issuer.DocumentPersonRole.PersonRole.person_id',
                        e
                      )
                  "
                  :editable="editable"
                />
              </dl>
            </div>

            <div>
              <dt class="col">Статус выполнения:</dt>
              <dl class="col">
                <EditableSelect
                  v-model="workTask.BaseWork.status_kind_id"
                  :options="workStatusKinds"
                  :editable="editable"
                />
              </dl>
            </div>

            <div>
              <dt class="col">Статус:</dt>
              <dl class="col">
                <EditableSelect
                  v-model="workTask.BaseWork.Document.status_id"
                  :options="workStatus"
                  :editable="editable"
                />
              </dl>
            </div>

            <!--            <div>-->
            <!--              <dt class="col">Процент выполнения:</dt>-->
            <!--              <dl class="col">хз как сделать</dl>-->
            <!--            </div>-->
          </div>
        </dl>
        <div class="d-inline-flex gap-5">
          <div>
            <dt class="col">Стоимость:</dt>
            <dl class="col mb-0">
              {{ workTask.totalCost }}
            </dl>
          </div>

          <div>
            <dt class="col">Автор:</dt>
            <dl class="col">
              {{ authorName }}
            </dl>
          </div>
        </div>
        <div>
          <dt class="col">Инструкция:</dt>
          <dl class="col">
            <div class="input-group p-0">
              <textarea
                type="text"
                v-model="workTask.instruction"
                rows="5"
                :readonly="!editable"
                name="instruction"
                class="form-control"
                required
              ></textarea>
            </div>
          </dl>
        </div>
      </div>
      <Tabs
        :headers="[
          'Трудозатраты',
          'Машины и механизмы',
          'Материалы',
          'Прочие расходы',
        ]"
      >
        <template v-slot:tab-0>
          <LaborItems
            :value="workTask.OldWorkTask.LaborItems"
            :editable="editable"
          />
        </template>
        <template v-slot:tab-1>
          <WorkEquipmentItems
            :value="workTask.OldWorkTask.WorkEquipmentItems"
            :editable="editable"
          />
        </template>
        <template v-slot:tab-2>
          <MaterialItems :value="workTask.MaterialItems" :editable="editable" />
        </template>
        <template v-slot:tab-3>
          <MiscCostItems
            :value="workTask.OldWorkTask.MiscCostItems"
            :editable="editable"
          />
        </template>
      </Tabs>
    </div>
  </div>
</template>

<script>
import modelGenerator from "@/plugins/modelGenerator";
import { updateWorkTask } from "@/services/work/schemas";
import {
  API_work_view,
  API_workTask_create,
  API_workTask_delete,
  API_workTask_update,
  API_workTask_view,
} from "@/services/work";
import Modal from "@/components/UI/Modal";
import Tabs from "@/components/UI/Tabs";
import UIButton from "@/components/UI/Button";
import EditableInput from "@/components/UI/EditableInput";
import EditableSelect from "@/components/UI/EditableSelect";
import EditableSelectWithSearch from "@/components/UI/EditableSelectWithSearch";
import LaborItems from "@/views/Overview/order/workTask/components/laborItems";
import WorkEquipmentItems from "@/views/Overview/order/workTask/components/workEquipmentItems";
import MaterialItems from "@/views/Overview/order/workTask/components/materialItems";
import MiscCostItems from "@/views/Overview/order/workTask/components/miscCostItems";
import {
  API_assets_by_location_name,
  API_WorkTaskStatusList,
} from "@/services/api";
import PersonCombobox from "@/views/Overview/order/components/personCombobox";
import { getPersonName } from "@/plugins/getName";
import { API_getKindByModelName } from "@/services/references/kind";

export default {
  name: "WorkTaskViewEdit",
  components: {
    Tabs,
    Modal,
    UIButton,
    EditableInput,
    EditableSelect,
    EditableSelectWithSearch,
    PersonCombobox,
    LaborItems,
    WorkEquipmentItems,
    MaterialItems,
    MiscCostItems,
  },
  data() {
    return {
      orderInfo: null,
      workTask: null,
      workLocationId: null,
      smallAssetLocations: [],
      locationAssets: [],
      workStatusKinds: [],
      workTimeScheduleKind: [],
      workStatus: [],
      tableColumns: {
        workEquipmentItems: [
          {
            name: "Наименование",
            path: "WorkIdentifiedObject.IdentifiedObject.enproCode",
          },
        ],
        materialItems: [
          {
            name: "Тип материала",
            path: "WorkIdentifiedObject.IdentifiedObject.enproCode",
          },
          {
            name: "Количество",
            path: "WorkIdentifiedObject.IdentifiedObject.enproCode",
          },
          {
            name: "ЕИ",
            path: "WorkIdentifiedObject.IdentifiedObject.enproCode",
          },
        ],
      },
    };
  },
  computed: {
    editable: function () {
      switch (this.$attrs.mode) {
        case "edit":
        case "create":
          return true;
        default:
          return false;
      }
    },
    titlePage: function () {
      switch (this.$attrs.mode) {
        case "edit":
          return "Редактирование работы";
        case "create":
          return "Создание работы";
        default:
          return "Просмотр работы";
      }
    },
    authorName: function () {
      const person = this.lodash.get(this.workTask, "BaseWork.Document.author");
      return getPersonName(person);
    },
    getRangePeriod: function () {
      const timeSchedules = this.orderInfo.BaseWork.TimeSchedules;
      let startDates = [];
      let endDates = [];
      timeSchedules.forEach((work) => {
        startDates.push(new Date(work.TimeSchedule.scheduleInterval.start));
        endDates.push(new Date(work.TimeSchedule.scheduleInterval.end));
      });
      return {
        from: new Date(Math.min.apply(null, startDates)),
        to: new Date(Math.max.apply(null, endDates)),
      };
    },
  },
  async mounted() {
    this.orderInfo = await API_work_view(parseInt(this.$route.params.order));
    if (this.$attrs.mode !== "create") {
      API_workTask_view(this.$route.params.workTask).then((res) => {
        this.workTask = modelGenerator(updateWorkTask, res);
      });
    } else {
      this.workTask = modelGenerator(updateWorkTask, {
        BaseWork: {
          TimeSchedules: [
            {
              TimeSchedule: {
                scheduleInterval: {
                  start: this.getRangePeriod.from.toISOString(),
                  end: this.getRangePeriod.to.toISOString(),
                },
              },
              kind_id: 1,
            },
          ],
        },
      });
    }
    API_work_view(this.$route.params.order).then((res) => {
      API_assets_by_location_name(
        this.lodash.get(res, "BaseWork.WorkLocation.Location.name", null)
      ).then((resAssets) => {
        let _assets = [];
        resAssets.forEach((asset) => {
          const idx = this.lodash.findIndex(_assets, { id: asset.location.id });
          if (idx > -1) {
            _assets[idx].items.push({
              id: asset.id,
              value: asset.name,
            });
          } else {
            _assets.push({
              id: asset.location.id,
              value: asset.location.name,
              items: [
                {
                  id: asset.id,
                  value: asset.name,
                },
              ],
            });
          }
        });
        this.smallAssetLocations = _assets;
      });
    });
    API_WorkTaskStatusList().then((res) => (this.workStatus = res));
    API_getKindByModelName("WorkStatusKind").then((res) => {
      this.workStatusKinds = res;
    });
    API_getKindByModelName("WorkTimeScheduleKind").then((res) => {
      this.workTimeScheduleKind = res;
    });
  },
  methods: {
    goEdit() {
      this.$router.push({
        path: `/overview/order/${this.$route.params.order}/workTask/${this.$route.params.workTask}/edit`,
      });
    },
    goToView(order, workTask) {
      window.location.href = `/overview/order/${order}/workTask/${workTask}`;
    },
    update() {
      this.$refs.submit.setStatus(true);
      if (this.$attrs.mode === "create") {
        API_workTask_create(this.$route.params.order, this.workTask)
          .then((res) => {
            this.goToView(this.$route.params.order, res.id);
          })
          .finally(() => this.$refs.submit.setStatus(false));
      } else {
        API_workTask_update(
          this.$route.params.workTask,
          this.$route.params.order,
          this.workTask
        )
          .then((res) => {
            this.goToView(this.$route.params.order, res.id);
          })
          .finally(() => this.$refs.submit.setStatus(false));
      }
    },
    remove() {
      API_workTask_delete(this.$route.params.workTask).then(() => {
        this.$router.push({
          path: `/overview/order/${this.$route.params.order}/`,
        });
      });
    },
  },
};
</script>

<style scoped></style>

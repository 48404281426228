var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TableSlot',{attrs:{"columns":_vm.tableColumns.laborItems,"data":_vm.value,"actions":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('td',[_vm._v(_vm._s(row.WorkIdentifiedObject.IdentifiedObject.name))]),_c('td',[_c('EditableInput',{attrs:{"type":"number","editable":_vm.editable},model:{value:(row.laborDuration.value),callback:function ($$v) {_vm.$set(row.laborDuration, "value", $$v)},expression:"row.laborDuration.value"}})],1),_c('td',_vm._l((_vm.getCrew(row.ErpPersons)),function(person,idx){return _c('p',{key:idx},[_vm._v(" "+_vm._s(person)+" ")])}),0)]}},{key:"actions",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"d-inline-flex gap-2"},[_c('Modal',{attrs:{"title":"Состав","button-text":"Изменить состав","button-disabled":!_vm.editable},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',[_c('dt',{staticClass:"col"},[_vm._v("Подразделение:")]),_c('dl',{staticClass:"col"},[_c('EditableSelect',{attrs:{"options":_vm.crew.smallAsset,"editable":_vm.editable},model:{value:(_vm.crew.crewId),callback:function ($$v) {_vm.$set(_vm.crew, "crewId", $$v)},expression:"crew.crewId"}})],1)]),_c('div',_vm._l((_vm.crew.crewMembers),function(item){return _c('div',{key:item.id,staticClass:"form-check"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"checkbox","value":"","id":'crewMember' + item.id},domProps:{"checked":row.ErpPersons.find(
                      function (i) { return i.id ===
                        item.OperationPersonRole.PersonRole.Person.OldPerson
                          .id; }
                    )},on:{"input":function($event){return _vm.addOrRemoveCrewMember(row, item)}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":'crewMember' + item.id}},[_vm._v(" "+_vm._s(_vm.getCrewName( _vm.lodash.get( item, "OperationPersonRole.PersonRole.Person" ) ))+" ("+_vm._s(_vm.lodash.get( item, "OperationPersonRole.PersonRole.IdentifiedObject.name" ))+") ")])])}),0)]},proxy:true}],null,true)}),_c('UIButton',{attrs:{"left-icon":"bi-trash","float":"","disabled":!_vm.editable},on:{"click":function($event){return _vm.addOrRemove(row, index)}}})],1)]}}])}),_c('div',{staticClass:"d-flex justify-content-center py-2"},[_c('Modal',{attrs:{"title":"Трудозатраты","button-text":"Добавить","button-left-icon":"bi-plus","button-disabled":!_vm.editable},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('TableSlot',{attrs:{"columns":_vm.tableColumns.labor,"data":_vm.laborCodeList},on:{"rowClick":_vm.addOrRemove}})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
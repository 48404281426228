import { createTypeMaterialSchema } from "@/services/references/typeMaterial/schemas";

export const parse_type_material_to_create = (value) => {
  let data = Object.assign({}, createTypeMaterialSchema);
  data.WorkDocument.Document.IdentifiedObject.name = value.name;
  data.WorkDocument.Document.IdentifiedObject.enproCode = value.code;
  data.WorkDocument.Document.status_id = value.statusId;
  data.WorkDocument.Document.type = value.docType;
  data.quantity = value.quantity;
  data.estUnitCost.value = value.estUnitCost;
  data.stockItem = value.stockItem;
  data.costType = value.costType;
  return data;
};

export const parse_type_material_to_update = (value) => {
  let data = Object.assign({}, value);
  data.WorkDocument.Document.status_id = value.WorkDocument.Document.status.id;
  return data;
};
